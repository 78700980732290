
// import { useLockBodyScroll } from "@uidotdev/usehooks";
import { state } from "@state/store";
import { useSnapshot } from "valtio";
import { Icon } from "@iconify/react";
import { cn } from "@/lib/utils";


export default function GlobalLoader() {
    // useLockBodyScroll();
    const snap = useSnapshot(state);

    return <div className={cn("top-0 left-0 flex flex-col  justify-center items-center bg-primary/80 w-screen overflow-hidden min-h-screen", snap.processing ? "!z-[2147483647] fixed" : "z-[0] hidden")}>
        <Icon icon="svg-spinners:blocks-shuffle-3" className="text-teal-600 animate-bounce" width={200} height={200} />
        <span className="text-teal-600 text-lg mt-4 font-semibold animate-pulse">{snap.processing_text}</span>
    </div>
}
